<template>
  <p-dialog-content>
    <div v-if="invoice" class="flex items-start p-4 bg-green-100 rounded-lg space-x-4">
      <div class="w-1/4">
        <p-text :label="$tk('Reports.Invoice')"><span class="font-bold">{{ invoice.id }}</span></p-text>
        <p-text class="mt-4" :label="type === 'customer' ? $tk('Common.General.Purchase') : $tk('Common.General.Sale')"><span class="font-bold">{{ invoice.saleId || invoice.purchaseId }}</span></p-text>
      </div>
      <div class="w-1/4">
        <p-text :label="$tk('Common.General.Date')"><span class="font-bold">{{ invoice.invoiceDate | date }}</span></p-text>
        <p-text class="mt-4" :label="$tk('Reports.Due')"><span class="font-bold">{{ invoice.dueDate | date }}</span></p-text>
      </div>
      <div class="w-1/4">
        <p-text :label="$tk('Reports.AmountIncludingVat')"><span class="font-bold">{{ numberFormat(invoice.amount) }}</span></p-text>
        <p-text class="mt-4" :label="$tk('Reports.Vat')"><span class="font-bold">{{ numberFormat(invoice.amountTax) || 0 }}</span></p-text>
      </div>
      <div class="w-1/4">
      <p-text :label="$tk('Common.General.Name')"><span class="font-bold">{{ invoice.invoiceName }}</span></p-text>
      <p-text class="mt-4" :label="$tk('Common.General.Reference')"><span class="font-bold">{{ invoice.purchOrderFormNum + ' ' + invoice.customerRef }}</span></p-text>
      </div>
    </div>

    <p-loader class="mt-8" v-if="isLoading" />

    <div v-else>
      <h2 class="mt-8">{{$tk('Reports.Payment', true)}}</h2>
      <table class="w-full mt-4 text-xs">
        <thead>
          <tr>
            <th class="text-left"  v-html="$tk('Reports.Invoice')"></th>
            <th class="text-left"  v-html="$tk('Common.General.Date')"></th>
            <th class="text-left"  v-html="$tk('Reports.Type')"></th>
            <th class="text-left"  v-html="$tk('Reports.Due')"></th>
            <th class="text-left"  v-html="$tk('Reports.Closed')"></th>
            <th class="text-right" v-html="$tk('Reports.Currency')"></th>
            <th class="text-right" v-html="$tk('Reports.AmountIncludingVat')"></th>
            <th class="text-right" v-html="$tk('Reports.Paid')"></th>
          </tr>
        </thead>
         <tbody>
          <tr v-for="(line, index) in invoice.customerTransactions" :key="index">
            <td><span class="font-bold">{{ line.invoiceId }}</span></td>
            <td>{{ line.transDate | date }}</td>
            <td> - </td>
            <td class="text-left">{{ line.dueDate | date }}</td>
            <td class="text-left">{{ line.closed ? $tk('Common.General.Yes') : $tk('Common.General.Yes') }}</td>
            <td class="text-right">{{ line.currencyId }}</td>
            <td class="text-right">{{ numberFormat(line.amount) }}</td>
            <td class="text-right">{{ numberFormat(line.amountSettled) }}</td>
          </tr>
        </tbody>
      </table>
      <h2 class="mt-8">{{$tk('Reports.Order', true)}}</h2>
      <table class="w-full mt-4 text-xs">
        <thead>
          <tr>
            <th class="text-left" v-html="$tk('Reports.Order')"></th>
            <th class="text-left" v-html="$tk('Reports.Product')"></th>
            <th class="text-left" v-html="$tk('Reports.Quality')"></th>
            <th class="text-left" v-html="$tk('Reports.SpecialTreatment')"></th>
            <th class="text-right" v-html="$tk('Reports.Quantity')" ></th>
            <th class="text-center" v-html="$tk('Reports.Currency')"></th>
            <th class="text-right" v-html="$tk('Common.Order.Price')"></th>
            <th class="text-right" v-html="$tk('Reports.AmountExcludingVat')"></th>
            <th class="text-right" v-html="$tk('Reports.Vat')"></th>
            <th class="text-right" v-html="$tk('Reports.AmountIncludingVat')"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, index) in invoiceLines" :key="index">
            <td><span class="font-bold">{{ line.saleIdOriginal || line.purchaseIdOriginal }}</span></td>
            <td>{{ line.name }}</td>
            <td>{{ line.qualityName }}</td>
            <td>{{ line.treatmentName }}</td>
            <td class="text-right">{{ line.quantity }}</td>
            <td class="text-center">{{ line.currencyId }}</td>
            <td class="text-right">{{ numberFormat(line.amountPerUnit) }}</td>
            <td class="text-right">{{ numberFormat(line.amount) }}</td>
            <td class="text-right">{{ numberFormat(line.amountTax) }}</td>
            <td class="text-right">{{ numberFormat(line.amount + line.amountTax) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </p-dialog-content>
</template>

<script>

import http from "@/http"

export default {

  props: {
    type: {
      type: String,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      invoiceLines: null,
      isLoading: false
    }
  },
  methods: {
    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), { minimumFractionDigits: 2 }).format(number)
    }
  },

  async created () {
    this.isLoading = true
    try {
      let params = {}

      switch (this.type) {
        case "customer":
          params.saleId = this.invoice.saleId
          break;
        case "vendor":
          params.purchaseId = this.invoice.purchaseId
          break;
      }

      this.invoiceLines = await http.get(`${this.type}invoicelines`, { params })

    } catch (error) {
      this.$store.dispatch("notify", { type: "negative", text: error.reason })
    }
    this.isLoading = false
  }

}
</script>


<style scoped>
  th {
    @apply px-2;
  }

  td {
    @apply p-2;
    border: 1px solid theme('colors.gray.300');
  }

  tr {
    background-color: theme('colors.gray.50');
  }

  tr:nth-child(odd) {
    background-color: theme('colors.gray.100')
  }

  tbody tr:hover {
    background-color: theme('colors.orange.200');
  }


</style>