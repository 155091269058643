<template>
  <p-dialog-content>
    <div class="relative flex h-full">
      <div class="absolute bottom-0 left-0 right-0 top-4">
        <p-loader />
      </div>
      <iframe :src="path" height="100%" width="100%" class="relative top-0">
      </iframe>
    </div>
  </p-dialog-content>
</template>

<script>
import { mapGetters } from "vuex";
import TokenService from "@/services/TokenService";
import { getConfig } from "@/config";

const config = getConfig();

export default {
  name: "InvoiceDialogInvoice",
  props: {
    invoice: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      site: location.hostname.split(".").join("_").toUpperCase(),
    };
  },
  computed: {
    ...mapGetters(["env"]),
    path() {
      return (
        config?.apiUrl +
        "InvoiceDocument?inline=true&type=FAKTURA&id=" +
        this.invoice.id +
        "&authtoken=" +
        encodeURIComponent(TokenService.getToken())
      );
    },
  },
};
</script>
